@font-face {
  font-family: "Jellee Roman";
  src: local("Jellee Roman"),
    url(./fonts/jellee-roman/jellee.roman.ttf) format("truetype");
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(./fonts/nunito/NunitoRegular-vmABZ.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(./fonts/nunito/NunitoSemibold-w1G82.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(./fonts/nunito/NunitoBold-1GD50.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-tel-input .form-control {
  border-radius: 10px !important;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  font-family: "Nunito";
  height: 48px;
}

*:focus {
  outline: none;
}

.react-tel-input .form-control:focus {
  border-color: #ff5b6a;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0;
}
.geosuggest__item--active {
  color: #fff;
}
