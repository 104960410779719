.App {
  text-align: left;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    /*animation: App-logo-spin infinite 20s linear;*/
  }
}

.App-header {
  background-color: #ffffff;
  min-height: 6rem;
  display: block;

  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

table {
  border: 1px solid #ddd;
  border-collapse: separate;
  border-left: 0;
  border-radius: 8px;
  border-spacing: 0;
}
thead {
  display: table-header-group;
  vertical-align: middle;
  border-color: inherit;
  border-collapse: separate;
}
tr {
  display: table-row;
  vertical-align: inherit;
  border-color: inherit;
}
th,
td {
  padding: 5px 4px 6px 4px;
  text-align: left;
  vertical-align: top;
  border-left: 1px solid #ddd;
}
td {
  border-top: 1px solid #ddd;
}
thead:first-child tr:first-child th:first-child,
tbody:first-child tr:first-child td:first-child {
  border-radius: 10px 0 0 0 !important;
}
thead:last-child tr:last-child th:first-child,
tbody:last-child tr:last-child td:first-child {
  border-radius: 0 0 0 10px !important;
}
th:first-of-type {
  border-top-left-radius: 10px !important;
}
th:last-of-type {
  border-top-right-radius: 10px !important;
}
tr:last-of-type td:first-of-type {
  border-bottom-left-radius: 10px !important;
}
tr:last-of-type td:last-of-type {
  border-bottom-right-radius: 10px !important;
}
table > thead.thead-dark > tr {
  line-height: 350%;
  text-align: center !important;
}
table > thead.thead-dark > tr > th {
  text-align: center !important;
  font-size: x-large;
}

thead.thead-dark {
  background-color: black !important;
  color: white !important;
}
table > thead.thead-dark > tr {
  height: 110%;
}

@media only screen and (max-width: 600px) {
  .App-logo {
    left: 0;
  }
}
